@import 'basic-components/dist/styles/basic.scss';

.ng-office-app__authed__content__body__item__creator {
  @include scrollbar;
  @include horizontal-scrollbar;
  height: calc(100% - #{size(7)} - 104px);
  margin-top: size(7);
  overflow: auto;
  overflow-x: hidden;
  width: 100%;

  .ng-dropdown__component__menu-list {
    max-height: calc(100vh - 400px);
  }

  .ng-office-app__authed__content__body__item__creator__title {
    @include subtitle-5;
    color: color(grey, dark);
    margin-bottom: size(1);
    width: 100%;
  }

  .ng-office-app__authed__content__body__item__creator__row {
    display: flex;
    margin-top: size(1);
    width: 100%;

    .ng-dropdown {
      margin-right: size(1);
      width: calc(50% - #{size(1)});

      .ng-dropdown__component__menu-list {
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
        width: 50%;
      }

      &:only-child {
        margin-right: 0;
        width: 100%;

        .ng-dropdown__component__menu-list {
          width: 100%;
        }
      }
    }

    .ng-tag {
      background-color: color(grey, light);
      border: solid 1px color(grey, hover);
      border-radius: size(.5);
      height: size(4);
      margin-top: size(1);
      padding: size(1) size(1.5);
      width: 100%;

      .ng-tag__label {
        @include body-2;
        display: flex;
        height: fit-content;
        padding: 0;
      }

      .ng-office-app__authed__content__body__item__creator__row__tag__key {
        color: color(black, base);
        margin-right: size(.5);
      }

      .ng-office-app__authed__content__body__item__creator__row__tag__value {
        color: color(grey, dark);
      }
    }
  }
}
