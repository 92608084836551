@import 'basic-components/dist/styles/basic.scss';

.ng-office-header-no-search {
  align-items: center;
  box-shadow: boxshadow(8);
  display: flex;
  justify-content: space-between;
  padding: size(1.5) size(1.5) size(1.5);
  width: 100%;

  .ng-office-header-no-search__icon {
    align-items: center;
    border: 1px solid color(grey, base);
    border-radius: size(.5);
    display: flex;
    height: size(5);
    justify-content: center;
    margin-right: size(1);
    width: size(5);
  }

  .ng-office-header-no-search__title {
    @include body-2;
    align-items: center;
    border: 1px solid color(grey, base);
    color: color(grey, dark);
    display: flex;
    height: size(5);
    letter-spacing: 2;
    padding-left: size(1);
    width: calc(100% - #{size(6)});
  }
}
