@import 'basic-components/dist/styles/basic.scss';

.ng-office-app__authed__content__body__item__row {

  &.ng-office-app__authed__content__body__item__row--loaded-file {
    align-items: center;
    border-radius: size(1);
    box-shadow: boxshadow(2);
    display: flex;
    height: size(5);
    margin-bottom: size(.5);
    padding-left: size(2);
    padding-right: size(2);
    transition: background-color .2s ease-in;
    width: calc(100% - #{size(.25)});

    &:hover {
      background-color: color(blue, hover);
    }

    .ng-office-app__authed__content__body__item__row__cell {
      @include body-2;
      color: color(black, base);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.ng-office-app__authed__content__body__item__row__cell--name {
        min-width: 96px;
        width: calc(100% - 188px - #{size(5.5)} - 96px - 128px);
      }

      &.ng-office-app__authed__content__body__item__row__cell--address {
        color: color(blue, bright);
        cursor: pointer;
        width: 128px;
      }

      &.ng-office-app__authed__content__body__item__row__cell--last-executed {
        width: 188px;
      }

      &.ng-office-app__authed__content__body__item__row__cell--icons {
        align-items: center;
        display: flex;
        height: 100%;
        width: size(5.5);
      }

      .ng-office-app__authed__content__body__item__row__cell__date {
        display: inline-block;
        margin-right: size(.5);
      }

      .ng-office-app__authed__content__body__item__row__cell__time {
        color: color(grey, medium);
        display: inline;
      }

      .ng-office-app__authed__content__body__item__row__cell__icon {
        cursor: pointer;
        height: size(2);

        &:first-of-type {
          margin-right: size(1.5);
        }
      }

      @media only screen and (max-width: 532px) {

        &.ng-office-app__authed__content__body__item__row__cell--last-executed {
          display: none;
        }

        &.ng-office-app__authed__content__body__item__row__cell--name {
          width: calc(100% - #{size(5.5)} - 128px);
        }
      }

      @media only screen and (max-width: 640px) and (min-width: 533px) {

        &.ng-office-app__authed__content__body__item__row__cell--name {
          width: calc(100% - 188px - #{size(5.5)} - 96px - 128px);
        }
      }
    }
  }
}
