@import 'basic-components/dist/styles/basic.scss';

.ng-office-app__settings {
  height: 100%;

  .ng-office-app__settings__body {
    height: calc(100% - size(8) - #{size(6)});
    padding: size(1.5) size(1);

    .ng-office-app__settings__body__section {

      .ng-office-app__settings__body__section__title {
        @include subtitle-5;
        border-bottom: 1px solid color(grey, hover);
        color: color(grey, medium);
        padding-bottom: size(.25);
        text-transform: uppercase;
        width: 100%;
      }

      .ng-office-app__settings__body__section__body {
        padding-top: size(.5);

        .ng-label {
          margin-bottom: size(.5);
        }

        .ng-text-field,
        .ng-dropdown,
        .ng-dropdown__component__control {
          max-width: 100%;
          width: 100%;

          .ng-dropdown__component__menu-list {
            width: 100%;
          }
        }
      }
    }
  }

  .ng-office-app__settings__footer {
    align-items: center;
    box-shadow: boxshadow(2);
    display: flex;
    height: size(6);
    justify-content: space-between;
    padding: 0 size(1.5);
    width: 100%;

    .text-button {

      p {
        align-items: center;
        display: flex;

        strong {
          font-weight: inherit;
          margin-left: size(.5);
        }
      }
    }
  }
}
