@import 'basic-components/dist/styles/basic.scss';

.ng-office-app__authed__content__body__item__row {

  &.ng-office-app__authed__content__body__item__row--loaded-serie {
    align-items: center;
    border-radius: size(1);
    box-shadow: boxshadow(2);
    display: flex;
    height: size(5);
    justify-content: space-between;
    margin-bottom: size(.5);
    padding-left: size(2);
    padding-right: size(2);
    transition: background-color .2s ease-in;
    width: calc(100% - #{size(.25)});

    &:hover {
      background-color: color(blue, hover);
    }

    .ng-office-app__authed__content__body__item__row__cell {
      border-left: 1px solid color(grey, hover);
      overflow: hidden;
      padding-left: size(1.5);
      padding-right: size(1.5);

      &:first-of-type {
        border-left: 0;
      }

      .ng-office-app__authed__content__body__item__row__cell__bottom,
      .ng-office-app__authed__content__body__item__row__cell__top {
        @include body-2;
        color: color(black, base);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .ng-office-app__authed__content__body__item__row__cell__top {
        color: color(grey, dark);
      }

      &.ng-office-app__authed__content__body__item__row__cell--address {

        .ng-office-app__authed__content__body__item__row__cell__bottom {
          color: color(blue, bright);
          cursor: pointer;
        }
      }

      &.ng-office-app__authed__content__body__item__row__cell--icons {
        align-items: center;
        border-left: 0;
        display: flex;
        height: 100%;
        min-width: size(6);
        padding: 0;
        width: size(6);
      }

      .ng-office-app__authed__content__body__item__row__cell__icon {
        cursor: pointer;
        height: size(2);
      }

      @media only screen and (max-width: 532px) {

        &.ng-office-app__authed__content__body__item__row__cell--symbol,
        &.ng-office-app__authed__content__body__item__row__cell--columns {
          display: none;
        }
      }

      @media only screen and (max-width: 640px) and (min-width: 533px) {

        &.ng-office-app__authed__content__body__item__row__cell--symbol {
          display: none;
        }
      }
    }
  }
}
