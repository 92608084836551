@import 'basic-components/dist/styles/basic.scss';

.ng-office-app__authed__content__body__item__preview {
  bottom: 0;
  height: 112px;
  left: 0;
  position: fixed;
  right: 0;
  transition: height .15s ease-in;
  z-index: 1;

  .ng-office-app__authed__content__body__item__preview__cancel-bg {
    background-color: color(black, base);
    height: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .1s ease-in;
    transition-delay: .2s;
    z-index: 0;
  }

  .ng-office-app__authed__content__body__item__preview__box {
    background-color: color(white, base);
    bottom: 0;
    box-shadow: boxshadow(4);
    height: 112px;
    left: 0;
    padding: size(2) 0 size(.25);
    position: absolute;
    right: 0;
    transition: height .2s ease-in;
    z-index: 2;

    .ng-office-app__authed__content__body__item__preview__box__top {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: size(1);
      padding: 0 size(1.5);

      .ng-office-app__authed__content__body__item__preview__box__top__left,
      .ng-office-app__authed__content__body__item__preview__box__top__right {
        align-items: center;
        display: flex;
      }

      .ng-office-app__authed__content__body__item__preview__box__top__right {
        margin-left: size(1);
      }

      .ng-office-app__authed__content__body__item__preview__box__top__left__icon {
        cursor: pointer;
        height: size(2);
        margin-right: size(1.5);
        width: size(2);

        svg {
          transform: rotate(0deg);
          transform-origin: center;
          transition: transform .1s ease-in;
        }
      }

      .ng-office-app__authed__content__body__item__preview__box__top__left__label {
        @include body-2;
        align-items: center;
        color: color(grey, medium);
        display: flex;

        p {
          color: color(grey, dark);
          margin-left: size(.5);
        }
      }
    }

    .ng-office-app__authed__content__body__item__preview__box__bottom {
      @include scrollbar;
      height: calc(100% - #{size(5)});
      overflow: hidden;

      .ng-office-app__authed__content__body__item__preview__box__bottom__no-results {
        align-items: center;
        background-color: color(grey, background);
        border-radius: size(.5);
        display: flex;
        height: size(4);
        justify-content: center;
        margin-bottom: size(2);

        p {
          @include body-2;
          color: color(grey, dark);
        }
      }

      .ng-office-app__authed__content__body__item__preview__box__bottom__item {
        align-items: center;
        background-color: color(grey, background);
        border-radius: size(1.5);
        display: flex;
        height: size(6.5);
        justify-content: space-between;
        margin: 0 size(1.5) size(.5);
        overflow: auto;
        padding: 0 size(1.5);
        width: calc(100% - #{size(3)});

        .ng-office-app__authed__content__body__item__preview__box__bottom__item__entry {
          padding: size(.5);

          .ng-office-app__authed__content__body__item__preview__box__bottom__item__entry__key {
            @include body-2;
            color: color(grey, dark);
            margin-bottom: size(.5);
            white-space: nowrap;
          }

          .ng-office-app__authed__content__body__item__preview__box__bottom__item__entry__value {
            @include body-2;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &.ng-office-app__authed__content__body__item__preview--is-expanded {
    height: 100%;

    .ng-office-app__authed__content__body__item__preview__cancel-bg {
      height: 20%;
      opacity: .33;
    }

    .ng-office-app__authed__content__body__item__preview__box {
      height: 80%;

      .ng-office-app__authed__content__body__item__preview__box__bottom {
        overflow: auto;
      }
    }

    .ng-office-app__authed__content__body__item__preview__box__top__left .ng-office-app__authed__content__body__item__preview__box__top__left__icon {

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
