@import 'basic-components/dist/styles/basic.scss';

.ng-office-app__authed__content__body__item__options {
  background-color: color(white, base);
  border-radius: size(1);
  box-shadow: boxshadow(4);
  height: size(5);
  left: size(1.5);
  overflow: hidden;
  padding-left: size(1.5);
  padding-right: size(1.5);
  position: absolute;
  right: size(1.5);
  top: size(1.5);
  width: calc(100% - #{size(3)});
  z-index: 1;

  &.ng-office-app__authed__content__body__item__options--is-expanded {
    height: fit-content;
    overflow: auto;
    z-index: 2;

    .ng-office-app__authed__content__body__item__options__bottom {
      height: fit-content;
    }
  }

  .ng-office-app__authed__content__body__item__options__top {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: size(5);
    position: relative;
    width: 100%;

    .ng-office-app__authed__content__body__item__options__top__icon {
      height: size(2);
      margin-right: size(2);
      width: size(2);
    }

    .ng-office-app__authed__content__body__item__options__top__label {
      @include body-1;
    }

    .ng-office-app__authed__content__body__item__options__top__arrow {
      cursor: pointer;
      height: size(3);
      position: absolute;
      right: size(1);
      top: size(1);
      width: size(3);
    }
  }

  .ng-office-app__authed__content__body__item__options__bottom {
    height: 0;
    overflow: hidden;
    padding-bottom: size(1.5);
    width: 100%;

    .ng-office-app__authed__content__body__item__options__bottom__label {
      @include body-2;
      margin-bottom: size(.5);
      margin-top: size(1);
      width: 100%;
    }

    .ng-office-app__authed__content__body__item__options__bottom__value {
      width: 100%;

      .ng-dropdown {
        width: 100%;

        .ng-dropdown__component__menu-list {
          width: calc(100% - #{size(6)});
        }
      }

      .ng-text-field {
        max-width: unset;
        min-width: unset;
        width: 100%;
      }
    }

    .ng-office-app__authed__content__body__item__options__bottom__row {
      align-items: center;
      display: flex;
      margin-top: size(.5);
      width: calc(100% - #{size(.5)});

      .ng-office-app__authed__content__body__item__options__bottom__label {
        margin-right: size(1);
        margin-top: size(1);
        min-width: size(4);
        width: size(4);
      }

      .ng-office-app__authed__content__body__item__options__bottom__pickers {
        margin-top: size(1);

        .ng-radio__childs {
          @include body-2;
          margin-top: size(.25);
        }

        .ng-office-app__authed__content__body__item__options__bottom__pickers__dates {
          align-items: center;
          display: flex;
          margin-top: size(.5);

          .ng-date-picker,
          .ng-time-field {
            max-width: calc(50% - #{size(1)});
            width: calc(50% - #{size(1)});

            input {
              width: 100%;
            }
          }

          .ng-date-picker {

            &__clear-button {
              top: calc(50% - #{size(.5)});
            }
          }
        }
      }

      .ng-office-app__authed__content__body__item__options__bottom__row__item {
        margin-right: size(1.5);
        width: calc(50% - #{size(.75)});

        &:last-of-type {
          margin-right: 0;
        }

        .ng-dropdown__component__menu-list {
          width: calc(50% - #{size(4.5)});
        }
      }
    }
  }
}
