@import 'basic-components/dist/styles/basic.scss';

.ng-office-app__authed__content--upload {

  .ng-office-app__authed__content__body {

    .ng-office-app__authed__content__body__section {

      .ng-office-app__authed__content__body__section__title {
        @include subtitle-5;
        border-bottom: 1px solid color(grey, hover);
        color: color(grey, medium);
        padding-bottom: size(.25);
        text-transform: uppercase;
        width: 100%;
      }

      .ng-office-app__authed__content__body__section__body {
        padding-top: size(.5);

        .ng-label {
          margin-bottom: size(.5);
        }

        .ng-text-field,
        .ng-dropdown,
        .ng-dropdown__component__control {
          max-width: 100%;
          width: 100%;

          .ng-dropdown__component__menu-list {
            width: 100%;
          }
        }

        .ng-office-app__authed__content__body__section__body__cell__adress {
          display: flex;

          .ng-office-app__authed__content__body__section__body__cell__adress__colon {
            @include modal-title;
            color: color(gray, dark);
            padding: size(1) size(1) 0 size(1);
          }

          .ng-text-field {
            min-width: unset;

            .ng-text-field-input {
              text-transform: uppercase;
            }
          }
        }
      }

      .ng-office-app__authed__content__body__section__top-button,
      .ng-office-app__authed__content__body__section__body__icon-button {
        align-items: center;
        border-radius: unset;
        cursor: pointer;
        display: flex;
        margin-top: size(1);

        .ng-basic-button {
          margin-left: size(1);
        }
      }

      .ng-office-app__authed__content__body__section__body__icon-button {
        margin-bottom: size(2);

        .ng-basic-button {
          @include body-1;
          color: color(blue, bright);
        }
      }

      .ng-office-app__authed__content__body__section__top-button {
        background-color: color(grey, background);
        border: 1px solid color(grey, background);
        height: size(4);
        justify-content: center;
        width: 100%;

        .ng-basic-button {
          @include body-2;
          color: color(blue, bright);
        }
      }
    }
  }
}
