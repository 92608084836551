@import '~basic-components/dist/styles/basic.scss';

.ng-office-app__authed__content__body__section__body__metadata-row {

  &__remove-field {
    cursor: pointer;
    height: size(2);
    margin-left: size(.5);
    padding-top: size(1);
  }

  &:first-of-type {
    margin-top: size(3);
  }

  & > * {
    margin-right: size(1);
  }

  .ng-text-field {
    min-width: unset;
  }
}
