@import 'basic-components/dist/styles/basic.scss';

.ng-office-app__authed {
  height: 100%;

  .ng-office-app__authed__content {
    height: 100%;
    min-height: size(50);
    padding: size(1.5) 0 size(1);

    .ng-office-app__authed__content__header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 0 size(2) size(.5) size(1.5);
      width: calc(100% - #{size(3.5)});

      .ng-office-app__authed__content__header__icon {
        align-items: center;
        border: 1px solid color(grey, base);
        border-radius: size(.5);
        display: flex;
        height: size(5);
        justify-content: center;
        margin-right: size(1);
        width: size(5);
      }

      .ng-office-app__authed__content__header__search {
        height: size(5);
        width: calc(100% - #{size(6)});

        .ng-search {
          width: 100%;

          .ng-search__icon {
            height: size(2);
          }

          input {
            width: 100%;
          }
        }
      }

      .ng-office-app__authed__content__header__title {
        @include body-2;
        align-items: center;
        border: 1px solid color(grey, base);
        color: color(grey, dark);
        display: flex;
        height: size(5);
        letter-spacing: 2;
        padding-left: size(1);
        width: calc(100% - #{size(6)});
      }
    }

    .ng-office-app__authed__content__body {
      height: calc(100% - size(5.5));

      .ng-menu-tabs {
        height: 100%;
      }

      .ng-menu-tabs__titles__item {
        padding: size(1.5);

        .ng-menu-tabs__titles__item__icon {
          display: none;
        }
      }

      .ng-office-app__authed__content__body__item {
        @include scrollbar;
        height: calc(100% - #{size(7)});
        margin-top: size(2);
        overflow: auto;

        &.ng-office-app__authed__content__body__item--loaded {
          height: calc(100% - 76px);
        }

        .ng-office-app__authed__content__body__item__headers {
          box-shadow: 0 8px 10px -4px color(grey, hover);
          display: flex;
          padding: 0 size(3) size(1) size(3.5);
          width: 100%;

          .ng-office-app__authed__content__body__item__headers__header {
            @include column-title;
            color: color(grey, dark);

            &.ng-office-app__authed__content__body__item__headers__header--group {
              position: relative;

              .ng-filter__dropdown {
                left: size(-7.5);
                position: absolute;
                top: size(4);
              }
            }
          }
        }

        .ng-office-app__authed__content__body__item__body {
          @include scrollbar;
          height: calc(100% - #{size(5)});
          margin-top: size(2);
          overflow-y: scroll;
          padding: 0 size(1) 0 size(1.5);

          .ng-office-app__authed__content__body__item__body__scroll {
            height: calc(100% - #{size(.5)});
          }
        }

        .no-results-wrapper {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;

          .no-results {
            position: static;
            transform: unset;
          }
        }
      }
    }

    &--upload {
      padding: unset;

      .ng-office-app__authed__content__body {
        height: calc(100% - #{size(8)} - #{size(6)});
        overflow: auto;
        padding: size(1.5) size(1);
      }
    }

    .ng-office-app__authed__content__footer {
      align-items: center;
      background-color: color(white, base);
      box-shadow: boxshadow(2);
      display: flex;
      height: size(6);
      justify-content: center;
      padding: 0 size(1.5);
      width: 100%;
    }
  }

  .statuses-box {

    .statuses-box__trigger {
      background-color: color(white, base);
      border: 1px solid color(blue, bright);
      bottom: size(1);
      height: size(4);
      right: size(2);
      transition: background-color .2s ease-in;
      width: size(4);

      &.statuses-box__trigger--is-active,
      &:hover {
        background-color: color(blue, bright);

        .statuses-box__trigger__circle {

          .ng-history-icon {
            background-color: color(blue, bright);

            .ng-history-icon__sign {
              fill: color(white, base);
            }
          }
        }
      }

      .statuses-box__trigger__circle {
        height: size(2);
        width: size(2);

        .ng-history-icon {
          background-color: color(white, base);
          transition: background-color .2s ease-in;

          .ng-history-icon__sign {
            fill: color(blue, bright);
            transition: fill .2s ease-in;
          }
        }
      }
    }

    .statuses-box__block {
      bottom: size(6);
      display: flex;
      flex-direction: column;
      height: auto;
      right: size(2);

      .statuses-box__block__container {
        flex-grow: 1;
      }
    }
  }

  .ng-office-app__authed__footer {
    align-items: center;
    box-shadow: boxshadow(8);
    display: flex;
    height: size(5);
    justify-content: center;
    width: 100%;
  }
}
