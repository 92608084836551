@import 'basic-components/dist/styles/basic.scss';

.ng-office-app__authed__content__body__item--add-file {

  .ng-office-app__authed__content__body__item__headers__header {
    width: calc(50% - 24px);
  }

  .ng-dropdown__component__menu-list {
    max-height: calc(100vh - 300px);
  }

  .ng-office-app__authed__content__body__item__body__scroll__row__cell {
    @include body-2;
    color: color(black, base);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ng-office-app__authed__content__body__item__body__scroll__row__cell--name {
    width: 50%;
  }

  .ng-office-app__authed__content__body__item__body__scroll__row__cell--group {
    width: 50%;
  }
}
