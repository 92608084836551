@import 'basic-components/dist/styles/reset.css';
@import 'basic-components/dist/styles/basic.scss';

.ng-office-app {
  background-color: color(white);
  height: 100vh;
  overflow: auto;
  width: 100%;

  .login-page {

    &.login-page--is-mobile {
      padding: 0 size(1.5);
    }
  }

  .ng-office-app__fetching {
    height: 100px;
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    z-index: 9999;
  }

  .ng-dropdown__component__menu-portal {
    z-index: 5;
  }
}
