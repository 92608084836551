@import '~basic-components/dist/styles/basic.scss';

.ng-office-app__authed__content__body__item__body__scroll__row__action {
  cursor: pointer;
  height: size(2);

  &.ng-office-app__authed__content__body__item__body__scroll__row__action--add {

    .ng-add-icon__sign {
      fill: color(blue, bright);
    }
  }
}
