@import 'basic-components/dist/styles/basic.scss';

.ng-office-app__authed .ng-office-app__authed__content .ng-office-app__authed__content__body .ng-office-app__authed__content__body__item {

  &.ng-office-app__authed__content__body__item--add-serie {
    height: calc(100% - 40px);
    margin-top: 0;
    padding-left: size(1.5);
    padding-right: size(1.5);
    padding-top: size(1.5);
    position: relative;
  }
}
