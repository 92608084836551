@import 'basic-components/dist/styles/basic.scss';

.ng-office-app__authed__content__body__item {

  &.ng-office-app__authed__content__body__item--loaded {

    .ng-office-app__authed__content__body__item__headers {

      .ng-office-app__authed__content__body__item__headers__header {

        &--name {
          min-width: 96px;
          width: calc(100% - 188px - #{size(5.5)} - 96px - 128px - 12px);
        }

        &--address {
          width: 128px;
        }

        &--time {
          width: 188px;
        }

        @media only screen and (max-width: 532px) {

          &--name {
            width: calc(100% - #{size(5.5)} - 128px - 12px);
          }

          &--time {
            display: none;
          }
        }
      }
    }
  }
}
